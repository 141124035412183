<template>
    <div class="pt-5">
        <div class="container" v-if="ready">
            <Form v-if="!room.status"
                class="form bg-white"
                :socketId="echo.connector.pusher.connection.socket_id"
                @callRequested="checkStatus()" />

            <Status v-else-if="!showVideo"
                class="bg-white"
                :data="room"
                @requestShowVideo="showVideo = true"
                @requestCancel="cancel.modal = true"
                @cancelPayment="cancelPayment.modal = true"
                @showPayment="showPayment()" />

            <Video v-else
                class="video-wrapper"
                :data="room"
                @requestUnstart="unstart"
                @requestCancel="cancel.modal = true" />
        </div>

        <!-- Start modal -->
        <b-modal v-model="start.modal"
            size="md"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            :hide-header-close="true"
            :body-class="'text-center'"
            :footer-class="'d-flex justify-content-center mb-4'">

            <!-- Body -->
            <template #default>
                <h4 class="mb-4">Su consulta está lista para iniciar</h4>
                <span><span class="font-weight-semibold">Será atendido por {{ room.callerName }}</span>.<br />Desea unirse y comenzar con la video llamada?</span>
            </template>
            <!-- / Body -->

            <!-- Footer -->
            <template #modal-footer>
                <ladda-btn
                    :loading="start.canceling"
                    data-style="zoom-out"
                    class="btn btn-danger"
                    @click.native="cancelCall">No, Rechazar y finalizar</ladda-btn>
                <ladda-btn
                    :loading="start.loading"
                    data-style="zoom-out"
                    class="btn btn-primary"
                    @click.native="startCall">Si, unirme a la llamada</ladda-btn>
            </template>
            <!-- / Footer -->
        </b-modal>
        <!-- / Start modal -->

        <!-- Cancel modal -->
        <b-modal v-model="cancel.modal"
            size="md"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            :hide-header-close="true"
            :body-class="'text-center'"
            :footer-class="'d-flex justify-content-center mb-4'">

            <!-- Body -->
            <template #default>
                <h4 class="mb-4">Finalizar llamada</h4>
                <span>Su consulta será finalizada y eliminada de la cola de espera.<br />Desea continuar?</span>
            </template>
            <!-- / Body -->

            <!-- Footer -->
            <template #modal-footer>
                    <b-btn
                        data-style="zoom-out"
                        class="btn btn-secondary"
                        @click="cancel.modal = false">No, Continuar con la llamada</b-btn>
                    <ladda-btn
                        :loading="cancel.loading"
                        data-style="zoom-out"
                        class="btn btn-danger"
                        @click.native="cancelCall">Si, Finalizar</ladda-btn>
            </template>
            <!-- / Footer -->
        </b-modal>
        <!-- / Cancel modal -->

        <!-- Missed modal -->
        <b-modal v-model="missing.modal"
            size="md"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            :hide-header-close="true"
            :body-class="'text-center'"
            :footer-class="'d-flex justify-content-center mb-4'"
            :ok-only="true"
            :ok-title="'Entendido'">

            <!-- Body -->
            <template #default>
                <h4 class="mb-4">Llamada perdida</h4>
                <span><span class="font-weight-semibold">{{ oldCallerName }}</span> lo ha llamado pero no ha podido comunicarse.<br />Por favor aguarde a ser llamado nuevamente</span>
            </template>
            <!-- / Body -->
        </b-modal>
        <!-- / Missed modal -->

        <!-- Finished modal -->
        <b-modal v-model="finished.modal"
            size="md"
            :body-class="'text-center'"
            :footer-class="'d-flex justify-content-center mb-4'"
            :ok-only="true"
            :ok-title="'Entendido'">

            <!-- Body -->
            <template #default>
                <h4 class="mb-4">Llamada finalizada</h4>
                <span>
                    Gracias por utilizar nuestros servicios.<br /> Su llamada ha finalizado.
                </span>
            </template>
            <!-- / Body -->
        </b-modal>
        <!-- / Finished modal -->

        <!-- Rejected modal -->
        <b-modal v-model="rejected.modal"
            size="md"
            :body-class="'text-center'"
            :footer-class="'d-flex justify-content-center mb-4'"
            :ok-only="true"
            :ok-title="'Entendido'">

            <!-- Body -->
            <template #default>
                <h4 class="mb-4">Llamada rechazada</h4>
                <span>Su llamada ha sido colocada en la cola de espera.<br />Por favor, espere a ser atendido nuevamente por una de nuestras secretarias</span>
            </template>
            <!-- / Body -->
        </b-modal>
        <!-- / Rejected modal -->

        <!-- Payment modal -->
        <b-modal v-model="cancelPayment.modal"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            :hide-header-close="true"
            size="md"
            :body-class="'text-center'"
            :footer-class="'d-flex justify-content-center mb-4'">

            <!-- Body -->
            <template #default>
                <h4 class="mb-4">Cancelar el pago</h4>
                <span>Está seguro que desea cancelar el pago?<br />Su llamada será derivada nuevamente con una secretaria.</span>
            </template>
            <!-- / Body -->

            <!-- Footer -->
            <template #modal-footer>
                <b-button
                    variant="secondary"
                    @click="cancelPayment.modal = false; showPayment()">No, quiero pagar</b-button>
                <ladda-btn
                    :loading="cancelPayment.canceling"
                    data-style="zoom-out"
                    class="btn btn-danger"
                    @click.native="doCancelPayment">Cancelar pago</ladda-btn>
            </template>
            <!-- / Footer -->
        </b-modal>
        <!-- / Payment modal -->
    </div>
</template>

<!-- Components -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-flatpickr-component/vue-flatpickr-component.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-form-wizard/vue-form-wizard.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
import Video from '@/components/Video'
// import Steps from '@/components/Steps'
import Form from '@/components/Form'
import Status from '@/components/Status'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

const loadScript = (url, callback) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'

    if (script.readyState) {
        script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null
                callback()
            }
        }
    } else {
        script.onload = () => callback()
    }
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
}

export default {
    name: 'app',

    components: {
        Form,
        Video,
        Status
        // Steps
    },

    data: () => ({
        echo: null,
        ringtone: null,

        form: {
            docNumber: null,
            socketId: null,
            installationId: null
        },

        room: {
            name: null,
            twilioToken: null,
            status: null,
            callerName: null,
            callerAvatar: null,
            payment: null,
            message: null,
            title: null
        },

        oldCallerName: null,

        ready: false,
        showVideo: false,
        requestError: null,

        start: {
            modal: false,
            onClose: false,
            loading: false,
            canceling: false
        },

        cancel: {
            modal: false,
            onClose: false,
            loading: false
        },

        missing: {
            modal: false
        },

        finished: {
            modal: false
        },

        rejected: {
            modal: false
        },

        cancelPayment: {
            modal: false,
            canceling: false
        }
    }),

    updated () {
        // Remove loading state
        setTimeout(() => document.body.classList.remove('app-loading'), 1)
    },

    async mounted () {
        window.addEventListener('online', () => this.checkStatus())
        this.ringtone = new Audio(`${this.publicUrl}/message-tone.mp3`)

        await this.connect()
        this.subscribeChannels()
        this.checkStatus()

        loadScript('https://sdk.mercadopago.com/js/v2', this.handleScriptLoad)
    },

    methods: {
        handleScriptLoad () {
            // console.log('MP ready')
        },

        connect () {
            return new Promise((resolve, reject) => {
                window.Pusher = Pusher
                Pusher.logToConsole = false

                this.echo = new Echo({
                    broadcaster: 'pusher',
                    key: process.env.VUE_APP_PUSHER_KEY,
                    cluster: process.env.VUE_APP_PUSHER_CLUSTER
                })

                this.echo.connector.pusher.connection.bind('connected', (event) => {
                    return resolve(event)
                })

                this.echo.connector.pusher.connection.bind('error', (error) => {
                    return reject(error)
                })
            })
        },

        subscribeChannels () {
            const socketId = this.echo.connector.pusher.connection.socket_id
            if (socketId) {
                this.echo.channel('guest.' + socketId)
                    .listen('.room.finished', (payload) => {
                        // console.log('.room.finished')
                        // console.log(payload)
                        this.finished.modal = true
                        this.checkStatus()
                    })
                    .listen('.room.taken', (payload) => {
                        // console.log('.room.taken')
                        // console.log(payload)
                        this.checkStatus()
                    })
                    .listen('.room.takenover', (payload) => {
                        // console.log('.room.takenover')
                        // console.log(payload)
                        this.checkStatus()
                    })
                    .listen('.room.rejected', (payload) => {
                        // console.log('.room.rejected')
                        // console.log(payload)
                        // this.rejected.modal = true
                        this.checkStatus()
                    })
                    .listen('.room.derived', (payload) => {
                        // console.log('.room.derived')
                        // console.log(payload)
                        this.checkStatus()
                    })
                    .listen('.room.payment', (payload) => {
                        // console.log('.room.payment')
                        // console.log(payload)
                        this.checkStatus()
                    })
                    .listen('.room.started', (payload) => {
                        // console.log('.room.started')
                        // console.log(payload)
                        this.checkStatus()
                        this.playSound()
                    })
                    .listen('.room.unstarted', (payload) => {
                        // console.log('.room.unstarted')
                        // console.log(payload)
                        this.checkStatus()
                    })
            } else {
                alert('Error on WS channel')
            }
        },

        checkStatus () {
            axios.patch('/check-status', {
                socket_id: this.echo.connector?.pusher?.connection?.socket_id
            })
                .then((response) => {
                    const newRoom = {
                        name: response.data.room_name,
                        twilioToken: response.data.twilio_token,
                        status: response.data.room_status,
                        callerName: response.data.caller_name,
                        callerAvatar: response.data.caller_avatar,
                        payment: response.data.payment,
                        message: response.data.message,
                        title: response.data.title
                    }

                    // Check missing call
                    if (['started', 'attended'].includes(this.room.status) && !['started', 'attended'].includes(newRoom.status) && !this.showVideo) {
                        this.showVideo = false
                        this.start.modal = false
                        this.missing.modal = true
                        this.oldCallerName = this.room.callerName
                    }

                    // Handle show video
                    if (['started', 'attended'].includes(this.room.status) && !['started', 'attended'].includes(newRoom.status) && this.showVideo) {
                        this.showVideo = false
                        this.$root.$emit('stopTracks')
                    }
                    // Handle start
                    if (['started', 'attended'].includes(newRoom.status)) {
                        this.missing.modal = false
                        this.start.modal = true
                    } else {
                        this.start.modal = false
                    }

                    this.room = newRoom

                    // Handle show payment
                    if (newRoom.payment && newRoom.status === 'pending') {
                        this.showVideo = false
                        // this.showPayment()
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    this.ready = true
                })
        },

        cancelCall () {
            this.stopSound()
            this.start.canceling = true
            this.cancel.loading = true

            // this.localDataTrack.send('BYE')

            axios.delete('/cancel-call')
                .then((response) => {
                    this.checkStatus()
                    this.cancel.modal = false
                })
                .finally(() => {
                    this.cancel.loading = false
                })
        },

        setError (message) {
            this.logs.unshift(message)
        },

        showPayment () {
            const mp = new window.MercadoPago(process.env.VUE_APP_MERCADOPAGO_KEY, {
                locale: 'es-AR'
            })

            mp.checkout({
                preference: {
                    id: this.room.payment.preference_id
                },
                autoOpen: true,
                theme: {
                    elementsColor: '#00BD9F',
                    headerColor: '#00BD9F'
                }
            })
        },

        doCancelPayment () {
            this.cancelPayment.canceling = true

            axios.delete('/cancel-payment')
                .then((response) => {
                    this.cancelPayment.modal = false
                    this.checkStatus()
                })
                .finally(() => {
                    this.cancelPayment.canceling = false
                })
        },

        startCall () {
            this.stopSound()
            this.start.modal = false
            if (!this.showVideo) {
                this.showVideo = true
            } else {
                this.$root.$emit('doConnect')
            }
        },

        unstart () {
            axios.patch('/unstart-call')
                .then((response) => {
                    this.checkStatus()
                })
        },

        playSound () {
            this.ringtone.play()
                .catch((error) => {
                    console.info(error)
                })
        },

        stopSound () {
            this.ringtone.pause()
            this.ringtone.currentTime = 0
        }
    }
}
</script>
