<template>
    <div class="position-relative">
        <div v-if="!['started', 'attended'].includes(data.status)" class="floating-status text-center">
            <div class="position-relative">
                <div class="app-splash-screen-content">
                    <img src="/img/loading-logo.svg">
                </div>
                <div v-if="activeRoom" class="participant-name d-inline">
                    <span>con <span class="font-weight-semibold">{{ data.callerName }}</span></span>
                </div>
                <span v-if="!['started', 'attended'].includes(data.status)">{{ data.title }}<br />{{ data.callerName }}</span>
            </div>
        </div>

        <!-- Call status -->
        <div class="col-12">
            <div v-if="!connection.error" class="row bg-primary text-white py-1">
                <div class="col">
                    <small class="d-block">
                        <span class="font-weight-semibold">{{ $t('label.status') }}:</span>
                        <!-- localParticipant status -->
                        <span v-if="localParticipant.state">{{ $t('label.' + localParticipant.state) }}</span>
                        <!-- Spinner -->
                        <span v-if="['preparing_devices', 'connecting', 'reconnecting'].includes(localParticipant.state)"><i class="fas fa-circle-notch fa-spin"></i></span>

                        <span v-if="['connected', 'reconnected'].includes(localParticipant.state) && (!remoteParticipant.state || ['disconnected'].includes(remoteParticipant.state))">
                            <span> - {{ $t('label.waiting_for_patient') }}</span>
                            <span class="font-weight-semibold"> {{ data.callerName }}</span>
                            <!-- Spinner -->
                            <span v-if="!remoteParticipant.state || ['reconnecting', 'disconnected'].includes(remoteParticipant.state)"><i class="fas fa-circle-notch fa-spin"></i></span>
                        </span>

                        <span v-if="['connected', 'reconnected'].includes(localParticipant.state) && ['connected', 'reconnected'].includes(remoteParticipant.state)">
                            <span>{{ $t('label.with') }}</span>
                            <span class="font-weight-semibold"> {{ data.callerName }}</span>
                        </span>

                        <span v-if="['connected', 'reconnected'].includes(localParticipant.state) && ['reconnecting'].includes(remoteParticipant.state)">
                            <span> - </span>
                            <span class="font-weight-semibold"> {{ data.callerName }}</span>
                            <span>{{ $t('label.is_reconnecting') }}</span>
                            <i class="fas fa-circle-notch fa-spin"></i>
                        </span>

                    </small>
                    <!--small class="d-block" v-if="localParticipant.error">{{ localParticipant.error }}</small-->
                </div>
            </div>
            <div v-else class="row bg-danger text-white py-1">
                <div class="col-12">
                    <small class="d-block"><span class="font-weight-semibold">Error:</span> {{ connection.error }}</small>
                </div>
            </div>
        </div>
        <!-- / Call status -->

        <!-- / Video -->
        <div :class="mainTrack === 'local' ? 'primary-video-track' : 'secondary-video-track'">
            <div v-if="mainTrack === 'remote'" class="toggle-main-track" @click="mainTrack = 'local'"></div>
            <div id="localTrack">
                <span class="local-devices-status">
                    <small v-if="localAudio.error" class="d-block my-1 bg-danger">{{ $t('label.audio_error') }}: {{ localAudio.error }}</small>
                    <small v-if="localVideo.error" class="d-block my-1 bg-danger">{{ $t('label.video_error') }}: {{ localVideo.error }}</small>
                </span>
            </div>
        </div>

        <div :class="mainTrack === 'remote' ? 'primary-video-track' : 'secondary-video-track'">
            <div v-if="mainTrack === 'local'" class="toggle-main-track" @click="mainTrack = 'remote'"></div>
            <div id="remoteTrack" :class="{'disabled': !remoteVideo.isEnabled}">
                <span class="remote-devices-status">
                    <small v-if="remoteAudio.isEnabled === false" class="d-block my-1">El participante ha deshabilitado su audio</small>
                    <small v-if="remoteVideo.isEnabled === false" class="d-block my-1">El participante ha deshabilitado su video</small>
                </span>
            </div>
        </div>
        <!-- / Video -->

        <!-- Video actions -->
        <div class="video-actions text-white py-1 px-3 d-flex align-items-center py-2">
            <div class="d-flex justify-content-end flex-fill">
                <div>
                    <b-btn
                        :disabled="localVideo.error !== null"
                        size="sm"
                        type="button"
                        class="btn btn-default ml-2"
                        :title="'Deshabilitar video'"
                        @click="muteVideo">
                        <span class="fa-stack fa-xs">
                            <i class="fas fa-video fa-stack-2x"></i>
                            <i v-if="!localVideo.isEnabled" class="fas fa-slash fa-stack-2x"></i>
                        </span>
                    </b-btn>

                    <b-btn
                        :disabled="localAudio.error !== null"
                        size="sm"
                        type="button"
                        class="btn btn-default ml-2"
                        :title="'Deshabilitar audio'"
                        @click="muteAudio">
                        <span class="fa-stack fa-xs">
                            <i class="fas fa-microphone fa-stack-2x"></i>
                            <i v-if="!localAudio.isEnabled" class="fas fa-slash fa-stack-2x"></i>
                        </span>
                    </b-btn>

                    <b-btn
                        type="button"
                        size="sm"
                        variant="danger"
                        class="ml-2"
                        @click="$emit('requestCancel')">
                        Finalizar
                    </b-btn>
                </div>
            </div>
        </div>
        <!-- / Video actions -->
    </div>
</template>

<script>
import { connect, createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video'

export default {
    name: 'Video',

    props: [
        'data'
    ],

    data: () => ({
        activeRoom: null,

        connection: {
            loading: false,
            success: false,
            error: null
        },

        localParticipant: {
            state: null,
            error: null
        },

        remoteParticipant: {
            state: 'waiting',
            error: null
        },

        // Tracks
        mainTrack: 'local',

        localAudioTrack: {},
        localAudio: {
            isEnabled: false,
            isStarted: false,
            isStopped: false,
            error: null
        },

        localVideoTrack: {},
        localVideo: {
            isEnabled: false,
            isStarted: false,
            isStopped: false,
            error: null
        },

        remoteDataTrack: {},

        remoteAudioTrack: {},
        remoteAudio: {
            isEnabled: true,
            isStarted: false,
            isSwitchedOff: false,
            error: null
        },

        remoteVideoTrack: {},
        remoteVideo: {
            isEnabled: true,
            isStarted: false,
            isSwitchedOff: false,
            error: null
        }
    }),

    async mounted () {
        await this.initLocalMedia()
        if (this.data.twilioToken) {
            this.connect()
        }

        this.$root.$once('stopTracks', () => this.stopTracks())
        this.$root.$once('doConnect', () => this.connect())
    },

    computed: {
        mediaReady: function () {
            return !!this.localVideoTrack.id && !!this.localAudioTrack.id
        }
    },

    watch: {
        mediaReady: function (val) {
            if (val) { this.localParticipant.state = 'ready_to_start_call' }
        }
    },

    methods: {
        stopTracks () {
            this.disconnect()

            if (this.localVideoTrack.id) {
                console.log('stopping video track')
                this.localVideoTrack.stop()
                this.localVideoTrack.detach().forEach(function (element) {
                    element.srcObject = null
                    element.remove()
                })
                this.localVideoTrack = {}
            }

            if (this.localAudioTrack.id) {
                console.log('stopping audio track')
                this.localAudioTrack.stop()
                this.localAudioTrack.detach()
                this.localAudioTrack = {}
            }
        },

        async initLocalMedia () {
            console.log('starting tracks')
            this.localParticipant.state = 'preparing_devices'

            // Video track
            await createLocalVideoTrack({ width: 350 }).then((track) => {
                this.localVideoTrack = track

                const localMediaContainer = document.getElementById('localTrack')
                localMediaContainer.appendChild(track.attach())

                this.localVideo = {
                    isEnabled: track.isEnabled,
                    isStarted: track.isStarted,
                    isStopped: track.isStopped,
                    error: null
                }

                const VueThis = this
                track.on('enabled', function (track) { VueThis.localVideo.isEnabled = track.isEnabled })
                track.on('disabled', function (track) { VueThis.localVideo.isEnabled = track.isEnabled })
                track.on('started', function (track) { VueThis.localVideo.isStarted = track.isStarted })
                track.on('stopped', function (track) { VueThis.localVideo.isStopped = track.isStopped })
            }).catch(e => { this.localVideo.error = e.message })

            // Audio track
            await createLocalAudioTrack().then(track => {
                this.localAudioTrack = track

                this.localAudio = {
                    isEnabled: track.isEnabled,
                    isStarted: track.isStarted,
                    isStopped: track.isStopped,
                    error: null
                }

                const VueThis = this
                track.on('enabled', function (track) { VueThis.localAudio.isEnabled = track.isEnabled })
                track.on('disabled', function (track) { VueThis.localAudio.isEnabled = track.isEnabled })
                track.on('started', function (track) { VueThis.localAudio.isStarted = track.isStarted })
                track.on('stopped', function (track) { VueThis.localAudio.isStopped = track.isStopped })
            }).catch(e => { this.localAudio.error = e.message })
        },

        async connect () {
            console.log('connecting')
            this.connection.loading = true
            this.connection.error = null
            this.localParticipant.state = 'connecting'
            this.remoteParticipant.state = null
            this.localParticipant.error = null
            const VueThis = this

            const tracks = []
            if (this.localVideoTrack.id) tracks.push(this.localVideoTrack)
            if (this.localAudioTrack.id) tracks.push(this.localAudioTrack)

            const connectOptions = {
                name: this.data.name,
                audio: true,
                video: { width: 400 },
                tracks: tracks
                // logLevel: 'info'
            }

            await connect(this.data.twilioToken, connectOptions).then(function (room) {
                VueThis.activeRoom = room

                // Remote participant events
                room.participants.forEach(participant => {
                    console.log(participant.identity + ' joined the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'remote' }, 4000)
                })

                room.on('participantConnected', function (participant) {
                    console.log(participant.identity + ' joined the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'remote' }, 4000)
                })

                room.on('participantDisconnected', function (participant) {
                    console.log(participant.identity + ' left the Room')
                    if (![...VueThis.activeRoom.participants].length) {
                        VueThis.remoteParticipant.state = participant.state
                        setTimeout(function () { VueThis.mainTrack = 'local' }, 4000)
                    }
                })

                room.on('participantReconnected', participant => {
                    console.log(participant.identity + ' reconnected to the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'remote' }, 4000)
                })

                room.on('participantReconnecting', participant => {
                    console.log(participant.identity + ' is reconnecting to the Room')
                    VueThis.remoteParticipant.state = participant.state
                    setTimeout(function () { VueThis.mainTrack = 'local' }, 4000)
                })

                // Local participant events
                VueThis.localParticipant.state = room.localParticipant.state

                room.on('reconnected', () => {
                    console.log('Reconnected!')
                    VueThis.localParticipant.state = VueThis.activeRoom.localParticipant.state
                    if ([...VueThis.activeRoom.participants].length) {
                        VueThis.remoteParticipant.state = [...VueThis.activeRoom.participants][0][1].state
                    }
                })

                room.on('trackMessage', (data, track, participant) => {
                    console.log(data)
                })

                room.on('reconnecting', error => {
                    VueThis.localParticipant.state = VueThis.activeRoom.localParticipant.state
                    if (error.code === 53001) {
                        console.log('Reconnecting your signaling connection!', error.message)
                        VueThis.localParticipant.error = error.message
                    } else if (error.code === 53405) {
                        console.log('Reconnecting your media connection!', error.message)
                        VueThis.localParticipant.error = error.message
                    }
                })

                room.on('trackSubscribed', function (track, publication, participant) {
                    if (['audio', 'video'].includes(track.kind)) {
                        const remoteMediaContainer = document.getElementById('remoteTrack')
                        remoteMediaContainer.appendChild(track.attach())

                        if (track.kind === 'audio') {
                            VueThis.remoteAudio.isEnabled = track.isEnabled
                        } else if (track.kind === 'video') {
                            VueThis.remoteVideo.isEnabled = track.isEnabled
                        }

                        track.on('disabled', function (track) {
                            if (track.kind === 'audio') {
                                VueThis.remoteAudio.isEnabled = false
                            } else if (track.kind === 'video') {
                                VueThis.remoteVideo.isEnabled = false
                            }
                        })

                        track.on('enabled', function (track) {
                            if (track.kind === 'audio') {
                                VueThis.remoteAudio.isEnabled = true
                            } else if (track.kind === 'video') {
                                VueThis.remoteVideo.isEnabled = true
                            }
                        })
                    }
                })

                room.on('trackUnsubscribed', function (track, publication, participant) {
                    if (!['data'].includes(track.kind)) {
                        track.detach().forEach(function (mediaElement) {
                            mediaElement.remove()
                        })
                    }
                })

                room.on('disconnected', function (room, error) {
                    VueThis.localParticipant.state = room.localParticipant.state

                    if (error) {
                        console.log('Unexpectedly disconnected:', error.code, error.message)
                        VueThis.localParticipant.error = error.message
                        if ([53405, 53216].includes(error.code)) { VueThis.$emit('requestUnstart') }
                    }

                    room.localParticipant.tracks.forEach(function (localTrack) {
                        if (!['data'].includes(localTrack.kind)) {
                            // localTrack.track.stop()
                            // localTrack.track.detach()
                        }
                    })

                    VueThis.resetData()
                })
            }).catch((error) => {
                this.connection.error = error
            })

            this.connection.loading = false
        },

        async disconnect () {
            if (this.activeRoom) {
                await this.activeRoom.disconnect()
                this.resetData()
            }
        },

        muteVideo () {
            this.localVideoTrack.isEnabled ? this.localVideoTrack.disable() : this.localVideoTrack.enable()
        },

        muteAudio () {
            this.localAudioTrack.isEnabled ? this.localAudioTrack.disable() : this.localAudioTrack.enable()
        },

        resetData () {
            this.activeRoom = null

            this.connection = {
                loading: false,
                success: false,
                error: null
            }

            this.localParticipant = {
                state: this.mediaReady ? 'ready_to_start_call' : null,
                error: null
            }

            this.remoteParticipant = {
                state: this.mediaReady ? 'ready_to_start_call' : null,
                error: null
            }

            // Tracks
            this.mainTrack = 'local'

            this.remoteAudio = {
                isEnabled: true,
                isStarted: false,
                isSwitchedOff: false,
                error: null
            }

            this.remoteVideo = {
                isEnabled: true,
                isStarted: false,
                isSwitchedOff: false,
                error: null
            }
        }
    }
}
</script>
