<template>
    <div class="d-flex flex-column py-5 justify-content-center h-100">
        <div class="align-self-stretch">
            <b-row>
                <b-col class="col-12 mb-2 text-center">
                    <h3 class="d-block mb-0" v-if="data.status === 'taken'">Contactando a la secretaria</h3>
                    <h3 class="d-block mb-0" v-else>{{ data.title }}</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-12 mb-4 text-center">
                    <span class="d-block text-muted w-50 mx-auto">{{ data.message }}</span>
                </b-col>
            </b-row>
            <b-row v-if="data.status === 'created'">
                <b-col class="col-12 mb-5 mt-4 text-center">
                    <div class="splash-screen">
                        <div class="app-splash-screen-content">
                            <img src="/img/loading-logo.svg">
                        </div>
                    </div>
                </b-col>
            </b-row>

            <div v-if="data.status !== 'pending'">
                <b-row>
                    <b-col v-if="data.callerName" class="col-6 offset-3 mb-4 text-center">
                        <b-avatar
                            size="10.4rem"
                            class="my-2 shadow-sm"
                            :variant="'default'"
                            :src="data.callerAvatar || `${publicUrl}/img/avatars/generic.png`">
                        </b-avatar>
                        <div class="row">
                            <div class="col-10 offset-1">
                                <span v-if="data.callerName" class="d-block font-weight-semibold">{{ data.callerName }}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="col-12 mt-2 text-center">
                        <b-btn
                            variant="primary d-inline"
                            @click="$emit('requestShowVideo')"
                            >Habilitar la Cámara</b-btn>
                        <b-btn
                            variant="danger d-inline"
                            @click="$emit('requestCancel')"
                            >Finalizar llamada</b-btn>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <b-row>
                    <b-col class="col-12 mt-2 mb-4 text-center">
                        <span>Para continuar con su consulta, por favor realice el pago.<br /> Una vez acreditado, será derivado con su Médico</span>
                        <img
                            class="d-block mt-4 mx-auto"
                            src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/575X40.jpg"
                            title="Mercado Pago - Medios de pago"
                            alt="Mercado Pago - Medios de pago"
                            style="width: 60%; height:auto" />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="col-12 mt-2 text-center">
                        <b-btn
                            variant="danger d-inline"
                            @click="$emit('cancelPayment')">Cancelar pago</b-btn>
                        <b-btn
                            variant="primary d-inline"
                            @click="$emit('showPayment')">Pagar consulta</b-btn>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<!-- Splash screen -->
<style>
    .app-splash-screen-content {
        position: relative;
        -webkit-animation: appSplashScreenAnimation 1.2s ease-in-out 0s infinite;
        animation: appSplashScreenAnimation 1.2s ease-in-out 0s infinite;
    }

    @-webkit-keyframes appSplashScreenAnimation {
        0%,
        20% {
            -webkit-transform: translate(-50%, -50%) rotateY(0);
            transform: translate(-50%, -50%) rotateY(0);
        }
        50% {
            -webkit-transform: translate(-50%, -50%) rotateY(180deg);
            transform: translate(-50%, -50%) rotateY(180deg);
        }
        80%,
        100% {
            -webkit-transform: translate(-50%, -50%) rotateY(360deg);
            transform: translate(-50%, -50%) rotateY(360deg);
        }
    }

    @keyframes appSplashScreenAnimation {
        0%,
        20% {
            -webkit-transform: translate(-50%, -50%) rotateY(0);
            transform: translate(-50%, -50%) rotateY(0);
        }
        50% {
            -webkit-transform: translate(-50%, -50%) rotateY(180deg);
            transform: translate(-50%, -50%) rotateY(180deg);
        }
        80%,
        100% {
            -webkit-transform: translate(-50%, -50%) rotateY(360deg);
            transform: translate(-50%, -50%) rotateY(360deg);
        }
    }
</style>
<!-- / Splash screen -->

<script>

export default {
    name: 'Status',

    props: {
        data: {
            type: Object
        }
    },

    data: () => ({
    })
}
</script>
