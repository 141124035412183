<template>
    <div class="d-flex flex-column py-5 px-md-4 px-lg-0 justify-content-center h-100">
        <ValidationObserver ref="form" class="col-lg-6 offset-lg-3" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(requestCall)">
                <b-form-row class="mb-4">
                    <b-form-group class="col-12 mb-2">
                        <h4 class="d-block mb-0">Necesito ser Atendido</h4>
                        <small class="d-block text-muted">Completá el formulario con tu DNI y Dirección de correo electrónico (opcional) para contactarte con nuestra secretaria</small>
                    </b-form-group>
                </b-form-row>
                <b-form-row class="mb-4">
                    <!-- Document -->
                    <ValidationProvider class="col-12 mb-3" vid="doc_number" name="Documento" mode="eager" rules="required|alpha_num|max:8|min:7" v-slot="{ errors }">
                        <b-form-group class="mb-2" :class="{ 'mb-3': errors.length}">
                            <template #label>Documento <span class="text-danger">*</span></template>
                            <b-input v-model="docNumber"
                                name="docNumber"
                                :class="{ 'is-invalid': errors.length}" />
                            <small class="text-danger position-absolute" v-for="error in errors" :key="error">{{ error }}</small>
                        </b-form-group>
                    </ValidationProvider>
                    <!-- / Document -->

                    <!-- Email -->
                    <ValidationProvider class="col-12" vid="email" name="Email" mode="eager" rules="email" v-slot="{ errors }">
                        <b-form-group class="mb-2" :class="{ 'mb-3': errors.length}">
                            <template #label>Email</template>
                            <b-input v-model="email"
                                name="email"
                                :class="{ 'is-invalid': errors.length}" />
                            <small class="text-danger position-absolute" v-for="error in errors" :key="error">{{ error }}</small>
                        </b-form-group>
                    </ValidationProvider>
                    <!-- / Email -->
                </b-form-row>

                <b-form-row class="mb-0">
                    <b-form-group class="col-12 mb-2">
                        <ladda-btn
                            type="submit"
                            :loading="loading"
                            data-style="zoom-out"
                            class="btn btn-primary d-block w-100 mx-0"
                            >Quiero atenderme</ladda-btn>
                    </b-form-group>
                </b-form-row>
            </b-form>
        </ValidationObserver>
    </div>
</template>

<script>

export default {
    name: 'Form',

    props: {
        socketId: String
    },

    data: () => ({
        docNumber: null,
        email: null,
        loading: false
    }),

    methods: {
        requestCall () {
            this.loading = true

            axios.post('/request-call', {
                doc_number: this.docNumber,
                email: this.email,
                websocket_token: this.socketId
            })
                .then((response) => {
                    this.$emit('callRequested')
                })
                .catch((e) => {
                    console.log(e.response)
                    if (e.response.status === 422) this.$refs.form.setErrors(e.response.data.details)
                    this.$emit('requestError', e.response.data.message)
                })
                .finally((e) => {
                    this.loading = false
                })
        }
    }
}
</script>
