// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'

// 3th party libraries import
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import axiosCancel from 'axios-cancel-plugin'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'

// Components import
import LaddaBtn from '@/vendor/libs/ladda/Ladda'

// Mixins import
import localizationsMixin from '@/mixins/localizations'
import { localize, ValidationObserver, ValidationProvider } from 'vee-validate'

import '@/resources/rules/index'
import('vee-validate/dist/locale/es.json').then(locale => {
    localize('es', locale)
})

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false
Vue.router = router

Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(VueAxios, axios)

// Global RTL flag
Vue.mixin({
    data: globals
})

Vue.mixin(localizationsMixin)

// Libraries init
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers = {
    common: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*'
    }
}
window.axios = axios
axiosCancel(axios, {
    debug: false // default
})

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: {
        en: {
            label: {
                connected: 'Connected',
                with: 'with',
                preparing_devices: 'Preparing devices',
                status: 'Status',
                is_reconnecting: 'is reconnecting',
                ready_to_start_call: 'ready to start call'
            }
        },
        es: {
            label: {
                connected: 'Conectado',
                connecting: 'Conectando',
                with: 'con',
                preparing_devices: 'Preparando dispositivos',
                status: 'Estado',
                is_reconnecting: 'se está reconectando',
                ready_to_start_call: 'Esperando...'
            }
        }
    }
})

// Components init
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('LaddaBtn', LaddaBtn)

new Vue({
    router,
    i18n,
    mounted () {
        var uuid = localStorage.getItem('x-device-installation-id')
        if (!uuidValidate(uuid)) { localStorage.setItem('x-device-installation-id', uuidv4()) }
        axios.defaults.headers.common['x-device-installation-id'] = localStorage.getItem('x-device-installation-id')
    },
    render: h => h(App)
}).$mount('#app')
